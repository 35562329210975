import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      title,
      url,
      target,
      rel,
      style,
      dataSet,
      callback,
      fullWidth,
    } = this.props

    let type = 'button'

    let props = {
      className: `btn btn-${style} ${fullWidth ? 'btn-block' : ''}`,
    }

    if (url) {
      props.href = url
      type = 'a'
    }
    if (target) props.target = target
    if (rel) props.rel = rel

    if (callback && typeof callback == 'function') {
      props.onClick = () => callback(dataSet)
    }

    if (typeof dataSet !== 'undefined' && Object.keys(dataSet).length > 0) {
      let newDataSet = new Object()
      for (let dataProp in dataSet) {
        Object.assign(newDataSet, { [`data-${dataProp}`]: dataSet[dataProp] })
      }

      props = { ...props, ...newDataSet }
    }

    return (
      <div className={'dhsv_button'}>
        {React.createElement(type, props, decodeEntities(title))}
      </div>
    )
  }
}

function decodeEntities(encodedString) {
  var textArea = document.createElement('textarea')
  textArea.innerHTML = encodedString
  return textArea.value
}

Button.defaultProps = {
  style: 'primary',
}

Button.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  style: PropTypes.string,
  fullWidth: PropTypes.bool,
  dataSet: PropTypes.object,
  callback: PropTypes.func,
}

export default Button
